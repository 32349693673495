import moment from "moment";
import products from "./products";
import WebService from "./WebService";
import { toast } from "react-toastify";
const HelperService = {
  getFormatedDateForDetail(dt: any, format?: any) {
    if (dt) {
      var stillUtc = moment.utc(dt).toDate();
      if (format) {
        return moment(stillUtc).local().format(format);
      } else {
        return moment(stillUtc).local().format(
          localStorage.getItem("date_format")?  localStorage.getItem("date_format") +" hh:mm A" :
          "DD-MM-YYYY  hh:mm A"
        );
      }
    } else {
      return "";
    }
  },
  getFormatedNumber(dt: Number, prefix: any, suffix: any) {
    if (dt) {
      // if (prefix) {
      //   if (isFloat(dt))
      //     return (
      //       (prefix ? prefix : "") + "" + (dt === 0 ? 0 : Number(dt).toFixed(2))
      //     );
      //   return (prefix ? prefix : "") + "" + (dt === 0 ? 0 : Number(dt));
      // } else {
      //   if (isFloat(dt))
      //     return (
      //       (dt === 0 ? 0 : Number(dt).toFixed(2)) + "" + (suffix ? suffix : "")
      //     );
      //   return (dt === 0 ? 0 : Number(dt)) + "" + (suffix ? suffix : "");
      // }
      if (prefix) {
        return (prefix ? prefix : "") + "" + dt;
      } else {
        return dt + "" + (suffix ? suffix : "");
      }
    } else {
      if (prefix) {
        return (prefix ? prefix : "") + "" + 0;
      } else {
        return 0 + "" + (suffix ? suffix : "");
      }
    }
  },
  getFormatedStatusText(dt: string) {
    if (dt !== "") {
      dt = dt.replaceAll("_", " ");
      dt = dt[0].toUpperCase() + dt.slice(1).toLowerCase();
      return dt;
    } else {
      return "";
    }
  },
  titleCase(str: any) {
    if (str) {
      var str1 = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      return str1;
    }
  },
  removeUnderscore(str: any) {
    if (str) {

      const words = str.replace(/_/g, ' ').split(' ');
      const modifiedWords = [];

      for (const word of words) {
        if (word.toLowerCase().includes('utm')) {
          modifiedWords.push('UTM');
        } else if (word.includes('AC')) {
          modifiedWords.push('AC');
        } else {
          const camelCaseWord =
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          modifiedWords.push(camelCaseWord);
        }
      }

      const camelCaseString = modifiedWords.join(' ');

      return camelCaseString;
    }
  },
  truncate(str: any, limit: number) {
    if (str) {
      return str.length > limit ? str.substring(0, limit) + "..." : str;
    }
  },

  removeChar(str: any) {
    if (str) {
      var str1 = str.replace("_", " ");
      return str1;
    }
  },

  getFormatedDateForTimePicker(dt: any) {
    if (dt) {
      const date = moment.utc(dt).format("YYYY-MM-DD");
      const time = moment.utc(dt).format("hh:mm:ss");
      return `${date}T${time}`;
    }
  },

  abbrNum(number: any, decPlaces: any) {
    if (!number) {
      return number;
    }
    // number = number.toFixed(2);
    decPlaces = Math.pow(10, decPlaces);
    var abbrev = ["k", "m", "b", "t"];
    for (var i = abbrev.length - 1; i >= 0; i--) {
      var size = Math.pow(10, (i + 1) * 3);
      if (size <= number) {
        number = Math.round((number * decPlaces) / size) / decPlaces;
        number += abbrev[i];
        break;
      }
      else {
        if (number === 0) {
          number = 0
        } else {
          if (isFloat(Number(number))) {
            number = Number(number).toFixed(2);
          } else {
            number = Math.round(number);
          }
        }
      }
    }
    return number;
  },


  numberWithCommas(x: any) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (parts[1]) {
      parts[1] = parts[1].slice(0, 2)
    }
    return parts.join(".");
  },

  dateAgo(date: any) {
    if (!date) return "";
    var dateofvisit = moment(date, "YYYY-MM-DD");
    var today = moment();
    var days = today.diff(dateofvisit, "days");
    if (days > 0) {
      return moment.utc(date).local().format("MMM DD, hh:mm A");
    } else {
      return moment.utc(date).local().fromNow(true) + " ago";
    }
  },
  getTimes(startTime: any, endTime: any, interval: number) {
    let allTimes = [];
    while (startTime < endTime) {
      allTimes.push(startTime.format("YYYY-MM-DD HH:mm:ss"));
      startTime.add(interval, "minutes");
    }

    return allTimes;
  },
  getFormatedTime(d: any) {
    return d ? moment.utc(d).format("hh:mm A") : "";
  },

  minToTime(min: any) {
    const totalSeconds = Math.floor(min * 60);
    const days = Math.floor(totalSeconds / (24 * 3600));
    const hours = Math.floor((totalSeconds % (24 * 3600)) / 3600);
    const minutesRemaining = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);
    let ans = "";
    if (days > 0) {
      if (days === 1) {
        ans += `${days} day `;
      } else {
        ans += `${days} days `;
      }
    }
    if (hours > 0) {
      ans += `${hours} hr `;
    }
    if (minutesRemaining > 0) {
      ans += `${minutesRemaining} min `;
    }
    if (seconds > 0) {
      ans += `${seconds} sec `;
    }
    if (ans === "") ans = "0 sec";
    return ans;
  },
  removeHtmlTag(str: any) {
    if (str !== "") {
      const regex = /(<([^>]+)>)/gi;
      const result = str.toString().replace(regex, "");
      return result;
    } else {
      return str;
    }

  },
  isEmptyObject(data: object) {
    return Object.keys(data).length === 0;
  },
  convertTo12Hour(time24h: any) {
    if (!time24h) { return; }
    var timex = time24h.split(':');

    if (timex[0] !== undefined && timex[1] !== undefined) {
      var hor = parseInt(timex[0]) > 12 ? (parseInt(timex[0]) - 12) : timex[0];
      var minu = timex[1];
      var merid = parseInt(timex[0]) < 12 ? 'AM' : 'PM';

      var res = hor + ':' + minu + ' ' + merid;

      return res.toString();
    }
  },
  getAppType() {
    var full = window.location.host
    var parts = full.split('.');
    var sub = parts[0];
    return products[sub as keyof typeof products];
  },
  makeCall(number: any) {

    WebService.addLoader("clbtn");
    var body = {
      "number": number,
      "user_id": localStorage.getItem("agent_number")
    }
    // //  console.log(body);
    // return
    return WebService.postAPI({
      action: `user-voice-operator/make-call`,
      body: body,
    }).then((res: any) => {
      WebService.removeLoader("clbtn");

      toast.success("Connecting Call");
      // setSelectedUser("");

    }).catch((error) => {
      WebService.removeLoader("clbtn");
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      WebService.removeLoader("filterData");
    });
  }
};

function isFloat(x: any) {
  let regexPattern = /^-?[0-9]+$/;
  let result = regexPattern.test(x);
  if (result) {
    return false;
  } else {
    return true;
  }
}


export default HelperService;
