import React, { useEffect, useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import AccessService from "../../../Services/AccessService";
import bannerImg from "../../../assets/images/webmaxy_business.png";
import WebService from "../../../Services/WebService";
import { Link } from "react-router-dom";
// import bannerImg from "../../../assets/images/off-banner-layer-img.png";
interface PropData {
  page_type: any;
  count: any;
  type: any;
  code: any;
  onComplete: any;
  access_type: any;
}
export const AccessControl = (props: PropData) => {
  const [upgradeModal, setUpgradeModal] = useState(false);
  const [is_back, setBack] = useState(false);
  useEffect(() => {
    AccessService.checkFeature(props.page_type, props.count, props.type, props.code, props.access_type, function (t: any) {
      // if (t.is_access_denied) {
      //   navigate("/access-denied");
      // }else{
      //   if (t.flag) {
      //     props.onComplete(t.access)
      //   } else {
      //     setUpgradeModal(t.showModal)
      //     setBack(t.is_back)
      //   }
      // }
      props.onComplete({
        "can_read": 1,
        "can_write": 1,
        "can_full_access": 1,
      })
    });
  }, []);
  const redirectPlan = () => {
    window.open(WebService.getLoginUrl() + "/" + localStorage.getItem("platform")?.toLowerCase() + "/plan");
  }
  const closeModal = () => {
    window.history.back();
  }
  return (
    upgradeModal ?
      <Offcanvas show={upgradeModal} placement="end" className="righSide-modal size-md">
        <Form className='wmx-form-style' name="clonelist" id="clonelist" >
          <Offcanvas.Header>
            <Offcanvas.Title>Upgrade</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className='wmx-normal-form'>
            <div className=""></div>
            <div className="mb-4">
              <Row className="align-items-center">
                <Col md={12}>
                  <img alt="" src={bannerImg} style={{ width: "100%" }} />
                </Col>
              </Row>
            </div>
            <div className="mb-3">
              <p className="font-medium font-18 mb-0">Upgrade to WebMaxy BUSINESS</p>
              <p className="font-500 font-16 mb-0">
                Upgrade website for unlimited reports incresed data collection rate and access to advance features.
              </p>
            </div>
            <Button className="btn  btn-wmx w-100" onClick={redirectPlan}>
              Upgrade
            </Button>
            {is_back ? <Link to={""} className="text-center d-block py-2 w-100" onClick={closeModal} >  Back </Link>
              : <Link
                to={""} className="text-center d-block py-2 w-100" onClick={() => setUpgradeModal(false)} >
                Close  </Link>}
          </Offcanvas.Body>
        </Form>
      </Offcanvas> : <></>
  );
};
